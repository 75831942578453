import React from 'react';

import HeaderLogin from 'components/Header/HeaderLogin';
import Bottom from 'components/Bottom';
// eslint-disable-next-line no-unused-vars
import Logo from 'assets/imgs/logo.png';

const Contato = () => {
  return (
    <>
      <HeaderLogin />
      <div
        className="d-flex justify-content-center align-items-top m-5"
        style={{ minHeight: 'calc(100vh - 250px)' }}
      >
        <div>
          <h1>
            Caso tenha problemas na instalação e/ou acesso do aplicativo, <br />
            entre em contato conosco:
          </h1>
          <br />
          <div className="d-flex">
            <h5 className="mr-2">
              <b>Telefone para contato:</b>
            </h5>
            {/* <h5>+55 19 98411-0109</h5> */}
          </div>
          <div className="d-flex">
            <h5 className="mr-2">
              <b>E-mail para contato: </b>
            </h5>
            {/* <h5>viviane.veiga@multimaxi.com.br</h5> */}
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default Contato;
