/* eslint-disable */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Accordion } from 'react-bootstrap';

import './styles.scss';

import TitlePages from 'components/_base/TitlePages';
import SubHeader from 'components/SubHeader';

import { tabs } from '../_constants';

import ResumoMaterial from './ResumoMaterial';

const Resumo = (props) => {
  const location = useLocation();

  let model = props.model;
  let pedidoCriado = props.pedidoCriado;
  let entities = props.entities;
  let finalizar = props.finalizar;
  let setActiveTab = props.setActiveTab;

  const initialIndex = 0;
  const currentIndex =
    parseInt(location.pathname.split('/')[4] ?? '0') || initialIndex;

  return (
    <div className="wrapper-content-pedido-resumo">
      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Resumo das solicitações" />

      <div className="content-pedido-resumo">
        <div className="row">
          <div className="col-lg-12">
            <Accordion>
              {(model.solicitacoes || []).map((solicitacao, index) => {
                return (
                  <ResumoMaterial
                    key={index}
                    solicitacao={solicitacao}
                    loja={model.loja}
                    aprovadorMidiasDigitais={model.aprovadorMidiasDigitais}
                    aprovador={model.aprovador}
                    entregaDigital={model.entregaDigital}
                    entregaCentroDistribuicao={model.entregaCentroDistribuicao}
                    entregaLojasParticipantes={model.entregaLojasParticipantes}
                    entregaOutro={model.entregaOutro}
                    entities={entities}
                    pedidoCriado={pedidoCriado}
                    index={index}
                  />
                );
              })}
            </Accordion>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 text-left">
            <button
              className="btn btn-primary btn-navigation"
              onClick={() => setActiveTab(tabs.tabEd)}
            >
              <span>
                <FaAngleLeft size={26} />
              </span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col-lg-6 d-flex flex-row-reverse">
            <button
              className="btn btn-primary btn-navigation btn-navigation-right"
              onClick={() => finalizar()}
            >
              <span>FINALIZAR</span>
              <span>
                <FaAngleRight size={26} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resumo;
