import React from 'react';
import VMasker from 'vanilla-masker';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ className, value, onChange }) => {
  // eslint-disable-next-line no-shadow
  const valueOnMask = (value) => {
    let valueMask = VMasker.toPattern(value, '99999999999999999999');
    if (valueMask.length > 10) {
      valueMask = VMasker.toPattern(valueMask, '(99) 99999-9999');
    } else {
      valueMask = VMasker.toPattern(valueMask, '(99) 9999-9999');
    }
    return valueMask;
  };
  return (
    <input
      type="text"
      className={`form-control ${className}`}
      value={valueOnMask(value)}
      onChange={(event) => {
        onChange(VMasker.toPattern(event.target.value, '99999999999999999999'));
      }}
    />
  );
};
