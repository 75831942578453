/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';

import './styles.scss';
import CardItemArtes from './CardItemArtes';

const CardArtes = (props) => {
  const [artes, setArtes] = useState([]);
  let itemsPagination = [];

  useEffect(() => {
    if (props.data) {
      setArtes(props.data);
    }
  }, [props.data]);

  const maxPages = 5;

  itemsPagination.push(
    <Pagination.Prev
      onClick={() => {
        // verifica se está na primeira página
        if (props.pageNumber - 1 > 0) {
          props.changePage(props.pageNumber - 1);
        }
      }}
    />
  );

  // calucula a pagina inicial e final que será exibida, mantendo sempre pelo menos maxPages de opções
  const initialPage =
    // eslint-disable-next-line no-nested-ternary
    props.totalPages < maxPages
      ? 1
      : props.pageNumber + (maxPages - 1) < props.totalPages
      ? props.pageNumber
      : props.totalPages - (maxPages - 1);

  console.log(initialPage);

  const finalPage =
    props.pageNumber + (maxPages - 1) < props.totalPages
      ? props.pageNumber + (maxPages - 1)
      : props.totalPages;

  for (let number = initialPage; number <= finalPage; number++) {
    itemsPagination.push(
      <Pagination.Item
        key={number}
        active={number === props.pageNumber}
        onClick={() => {
          props.changePage(number);
        }}
      >
        {number}
      </Pagination.Item>
    );
  }

  itemsPagination.push(
    <Pagination.Next
      onClick={() => {
        // verifica se está na última página
        if (props.pageNumber < props.totalPages) {
          props.changePage(props.pageNumber + 1);
        }
      }}
    />
  );

  return (
    <>
      <div className="card-deck-artes">
        {artes.map((arte, index) => (
          <CardItemArtes
            key={`banco-artes-${index}`}
            arte={arte}
            onClickResumo={props.onClickResumo}
            handleSolicitarNovamente={props.handleSolicitarNovamente}
          />
        ))}
      </div>
      <div>
        {props.totalPages > 1 ? (
          <Pagination>{itemsPagination}</Pagination>
        ) : null}
      </div>
    </>
  );
};

export default CardArtes;
