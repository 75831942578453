/* eslint-disable */

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    if (Array.isArray(data)) {
      for (const item of data) buildFormData(formData, item, parentKey);
    } else {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    }
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

const lib = {
  jsonToFormData: function jsonToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
  },
};

export default lib;
