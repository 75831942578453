/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Input, Button, Table, FormGroup } from 'reactstrap';

import { Context } from 'context/AuthContext';
import TablePagination from 'components/TablePagination';
import SingleSelect from 'components/_base/SelectSingle';
import api from 'api';
import {
  GET_LIST_PAGED_USUARIOS,
  GET_SELECT_LIST_USUARIO_TIPO,
} from 'services/Admin';
import { GET_USUARIO } from 'services/Usuario';

const ContentAdminUsuarios = () => {
  const history = useHistory();
  const location = useLocation();
  const { showLoader, userData } = useContext(Context);

  const searchParams = new URLSearchParams(location.search);

  /* select lists tipos de usuario */
  const [selectListUsuarioTipo, setSelectListUsuarioTipo] = useState([]);

  /* filters */
  const [nome, setNome] = useState(searchParams.get('nome') ?? '');
  const [pageSize, setPageSize] = useState(
    searchParams.get('pageSize') ?? '20'
  );
  const [pageNumber, setPageNumber] = useState(
    searchParams.get('pageNumber') ?? '1'
  );
  const [usuarioTipoId, setUsuarioTipoId] = useState(
    searchParams.get('usuarioTipoId') ?? ''
  );

  const [lista, setLista] = useState([]);
  const [pageData, setPageData] = useState({});

  function getFilter() {
    return {
      nome,
      usuarioTipoId,
      pageNumber,
      pageSize,
    };
  }

  function getListPaged() {
    (async () => {
      showLoader(true);
      const filter = getFilter();
      const query = new URLSearchParams(filter).toString();

      history.push(`admin?${query}`);

      const result = await GET_LIST_PAGED_USUARIOS(filter);

      if (result?.value !== null) {
        setLista(result.value.data);
        setPageData(result.value);
      }

      showLoader(false);
    })();
  }

  useEffect(() => {
    (async () => {
      const result = await GET_SELECT_LIST_USUARIO_TIPO();
      setSelectListUsuarioTipo(result?.value || []);

      getListPaged();
    })();
  }, []);

  function clearFilter() {
    window.location = '/admin';
  }

  useEffect(() => {
    getListPaged();
  }, [pageNumber]);

  function logar(usuarioId) {
    showLoader(true);
    history.push(`admin/logar/${usuarioId}`);
  }

  function handlePageChange(pageNumberChange) {
    setPageNumber(pageNumberChange);
  }

  function renderTable() {
    if (!pageData) return null;

    return (
      <div className="row">
        <div className="col-md-12">
          <hr />

          {pageData.totalRows > 0 && (
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nome</th>
                  <th>Tipo Usuário</th>
                  <th>Superior</th>
                  <th>Regional</th>
                  <th>Situação</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {lista.map((item) => {
                  return (
                    <tr key={item.usuarioId}>
                      <td>{item.usuarioId}</td>
                      <td>{item.nome}</td>
                      <td>{item.usuarioTipo?.nome}</td>
                      <td>{item.usuarioSuperior?.nome}</td>
                      <td>{item.regional?.nome}</td>
                      <td>{item.ativo === true ? 'Ativo' : 'Inativo'}</td>
                      <td>
                        <Button
                          className="btn btn-custom"
                          onClick={() => logar(item.usuarioId)}
                          title="Logar"
                        >
                          Logar
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          <TablePagination
            pageData={pageData}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Card className="card-default">
            <CardBody>
              <form>
                <FormGroup row>
                  <label className="col-md-2 col-form-label text-md-right">
                    Nome
                  </label>
                  <div className="col-md-6">
                    <Input
                      className="from-control"
                      type="text"
                      placeholder=""
                      onChange={(e) => setNome(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          getListPaged();
                        }
                      }}
                      value={nome}
                    />
                  </div>
                </FormGroup>

                <FormGroup row>
                  <label className="col-md-2 col-form-label text-md-right">
                    Tipo Usuário
                  </label>
                  <div className="col-md-6">
                    <SingleSelect
                      options={selectListUsuarioTipo}
                      value={usuarioTipoId}
                      onChange={(value) => {
                        setUsuarioTipoId(value);
                      }}
                    />
                  </div>
                </FormGroup>

                <FormGroup row>
                  <div className="offset-md-2 col-md-8">
                    <Button
                      className="btn btn-default"
                      onClick={() => getListPaged()}
                      // disabled={loading}
                    >
                      <i className="fa fa-filter" />
                      &nbsp; Filtrar
                    </Button>
                    &nbsp;
                    <Button
                      className="btn btn-default"
                      onClick={() => clearFilter()}
                      // disabled={loading}
                    >
                      <i className="fa fa-eraser" />
                      &nbsp; Limpar
                    </Button>
                  </div>
                </FormGroup>
              </form>

              {renderTable()}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ContentAdminUsuarios;
