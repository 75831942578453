/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Context } from 'context/AuthContext';
import { Button } from 'react-bootstrap';
import { dateFormat } from 'helpers/Date.Helper';
import { getStringFromPrice } from 'pages/PedidoMateriais/ContentPedido/_constants';
import FilePreview from 'components/FilePreview';

import './styles.scss';

const SummarySolicitacao = (props) => {
  let pedido = props.pedido;
  let solicitacao = props.solicitacao;

  let valorTotal =
    (solicitacao?.valorDiagramacao ?? 0) +
    (solicitacao?.valorImpressao ?? 0) +
    (solicitacao?.valorFrete ?? 0);

  if (!pedido.pedidoId) return null;

  const { showLoader } = useContext(Context);

  function toDataURL(url) {
    showLoader(true);
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  async function forceDownload(url, tag) {
    url = url.split('?')[0];

    var urlsplit = url.split('/');

    var fileName = tag + '_' + urlsplit[urlsplit.length - 1];

    var url = process.env.REACT_APP_API_ROOT + 'arquivo/Download?url=' + url;

    const a = document.createElement('a');
    a.href = await toDataURL(url);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    showLoader(false);
  }

  return (
    <div className="summary-informacoes">
      <h3>Informações</h3>
      <table className="table table-striped">
        <tbody>
          {solicitacao.materialComunicacao && (
            <tr>
              <td className="table-row-title">Material</td>
              <td>{solicitacao.materialComunicacao.nome}</td>
            </tr>
          )}
          {pedido && pedido.solicitante && (
            <tr>
              <td className="table-row-title">Solicitante</td>
              <td>{pedido.solicitante?.nome}</td>
            </tr>
          )}
          {pedido && pedido.pedidoTipo && (
            <tr>
              <td className="table-row-title">Tipo</td>
              <td>{pedido.pedidoTipo?.nome}</td>
            </tr>
          )}
          {(solicitacao.valorDiagramacao ?? 0) > 0 && (
            <tr>
              <td className="table-row-title">Valor Diagramação</td>
              <td>
                {solicitacao.valorDiagramacao.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {(solicitacao.valorImpressao ?? 0) > 0 && (
            <tr>
              <td className="table-row-title">Valor Produção</td>
              <td>
                {solicitacao.valorImpressao.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {(solicitacao.valorFrete ?? 0) > 0 && (
            <tr>
              <td className="table-row-title">Valor Frete</td>
              <td>
                {solicitacao.valorFrete.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {valorTotal > 0 && (
            <tr>
              <td className="table-row-title">Valor Total</td>
              <td>
                {valorTotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
          )}
          {pedido && pedido.loja && (
            <tr>
              <td className="table-row-title">Cliente</td>
              <td>
                {pedido.loja.razaoSocial} ({pedido.loja.codigo})
              </td>
            </tr>
          )}
          {pedido && pedido.loja && (
            <tr>
              <td className="table-row-title">CNPJ</td>
              <td>{pedido.loja.cnpj}</td>
            </tr>
          )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.nomeFantasia && (
              <tr>
                <td className="table-row-title">Nome Fantasia</td>
                <td>{solicitacao.solicitacaoDados.nomeFantasia}</td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.dataVeiculacaoInicio && (
              <tr>
                <td className="table-row-title">Início da Vigência</td>
                <td>
                  {dateFormat(
                    solicitacao.solicitacaoDados.dataVeiculacaoInicio
                  )}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.dataVeiculacaoFim && (
              <tr>
                <td className="table-row-title">Fim da Vigência</td>
                <td>
                  {dateFormat(solicitacao.solicitacaoDados.dataVeiculacaoFim)}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.quantidadeLojasParticipantes > 0 && (
              <tr>
                <td className="table-row-title">Qtd. Lojas Participantes</td>
                <td>
                  {solicitacao.solicitacaoDados.quantidadeLojasParticipantes}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.quantidadeImpressosLoja > 0 && (
              <tr>
                <td className="table-row-title">Qtd. de Impressos por Loja</td>
                <td>{solicitacao.solicitacaoDados.quantidadeImpressosLoja}</td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.quantidadePaginas > 0 && (
              <tr>
                <td className="table-row-title">Qtd. de Páginas</td>
                <td>{solicitacao.solicitacaoDados.quantidadePaginas}</td>
              </tr>
            )}
          {solicitacao.solicitacaoTamanhos &&
            solicitacao.solicitacaoTamanhos.length > 0 && (
              <tr>
                <td className="table-row-title">Tamanhos (L x A)</td>
                <td>
                  {solicitacao.solicitacaoTamanhos.map((tamanho, index) => {
                    return (
                      <div key={index}>
                        {tamanho.largura +
                          'x' +
                          tamanho.altura +
                          tamanho.unidadeTamanho}
                      </div>
                    );
                  })}
                </td>
              </tr>
            )}

          {solicitacao.solicitacaoEspecificacoes &&
            solicitacao.solicitacaoEspecificacoes.length > 0 && (
              <tr>
                <td className="table-row-title">Especificações </td>
                <td>
                  {solicitacao.solicitacaoEspecificacoes.map(
                    (especificacao, index) => {
                      return (
                        <div key={index}>
                          Descrição: {especificacao.descricao}
                          {' - '}
                          Quantidade: {especificacao.quantidade}
                        </div>
                      );
                    }
                  )}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoAcaoPromocionais &&
            solicitacao.solicitacaoAcaoPromocionais.length > 0 && (
              <tr>
                <td className="table-row-title">Ações Promocionais</td>
                <td>
                  {solicitacao.solicitacaoAcaoPromocionais.map((acao) => {
                    return (
                      <div>
                        {acao.acaoPromocional.texto +
                          ': ' +
                          acao.solicitacaoAcaoPromocionalValores &&
                          acao.solicitacaoAcaoPromocionalValores.length > 0 &&
                          acao.solicitacaoAcaoPromocionalValores.map(
                            (acaoValor) => {
                              return (
                                acaoValor.acaoPromocionalCampo.texto +
                                ': ' +
                                acaoValor.valor +
                                ' '
                              );
                            }
                          )}
                      </div>
                    );
                  })}
                </td>
              </tr>
            )}

          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.extensaoArquivo && (
              <tr>
                <td className="table-row-title">Extensão do Arquivo</td>
                <td>{solicitacao.solicitacaoDados.extensaoArquivo}</td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.observacoesGerais && (
              <tr>
                <td className="table-row-title">Observações</td>
                <td>
                  <span className="text-format">
                    {solicitacao.solicitacaoDados.observacoesGerais}
                  </span>
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados && (
            <tr>
              <td className="table-row-title">Logo</td>
              <td>
                {solicitacao.solicitacaoDados.possuiLogo &&
                pedido &&
                pedido.loja &&
                pedido.loja.urlLogo ? (
                  <>
                    <a
                      href={pedido.loja.urlLogo}
                      target="_blank"
                      rel="noopener"
                    >
                      <img
                        className="resumo-logo"
                        src={pedido.loja.urlLogo}
                      ></img>
                    </a>
                    <Button
                      onClick={() => forceDownload(pedido.loja.urlLogo)}
                      download="Logo"
                    >
                      Baixar Logo
                    </Button>
                  </>
                ) : (
                  'Não Possui'
                )}
              </td>
            </tr>
          )}
          {solicitacao.solicitacaoDados &&
            (solicitacao.solicitacaoDados.possuiRodape ||
              solicitacao.solicitacaoRodapes?.length > 0) && (
              <tr>
                <td className="table-row-title">Rodapé</td>
                <td>
                  {solicitacao.solicitacaoDados.possuiRodape &&
                  pedido &&
                  pedido.loja &&
                  pedido?.loja?.urlRodape ? (
                    <>
                      <a
                        href={pedido.loja.urlRodape}
                        target="_blank"
                        rel="noopener"
                      >
                        <img
                          className="resumo-rodape-img"
                          src={pedido.loja.urlRodape}
                          onClick={() => forceDownload(pedido.loja.urlRodape)}
                        ></img>
                      </a>
                      <Button
                        onClick={() => forceDownload(pedido.loja.urlRodape)}
                        download="Rodapé"
                      >
                        Baixar Rodapé
                      </Button>
                    </>
                  ) : solicitacao.solicitacaoRodapes ? (
                    solicitacao.solicitacaoRodapes.map((item, idx) => {
                      return (
                        <div key={idx} className="resumo-rodape">
                          <table className="table mb-3">
                            <tbody>
                              <tr className="bg-transparent line-xs">
                                <td className="rodape-td-header" colSpan="2">
                                  Loja {idx + 1}
                                </td>
                              </tr>

                              <tr className="bg-transparent line-xs">
                                <td className="rodape-td-header">CEP</td>
                                <td>{item.cep}</td>
                              </tr>
                              <tr className="bg-transparent line-xs">
                                <td className="rodape-td-header">Endereço</td>
                                <td>{item.endereco}</td>
                              </tr>

                              {item.numero && (
                                <tr className="bg-transparent line-xs">
                                  <td className="rodape-td-header">Número</td>
                                  <td>{item.numero}</td>
                                </tr>
                              )}

                              {item.numero && (
                                <tr className="bg-transparent line-xs">
                                  <td className="rodape-td-header">
                                    Complemento
                                  </td>
                                  <td>{item.complemento}</td>
                                </tr>
                              )}
                              <tr className="bg-transparent line-xs">
                                <td className="rodape-td-header">Bairro</td>
                                <td>{item.bairro}</td>
                              </tr>
                              <tr className="bg-transparent line-xs">
                                <td className="rodape-td-header">Cidade</td>
                                <td>
                                  {item.cidade.nome} - {item.cidade.uf}
                                </td>
                              </tr>
                              <tr className="bg-transparent line-xs">
                                <td className="rodape-td-header">
                                  Horário de funcionamento
                                </td>
                                <td>{item.horarioFuncionamento}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      );
                    })
                  ) : (
                    'Não Possui'
                  )}
                </td>
              </tr>
            )}
          {solicitacao.solicitacaoDados &&
            solicitacao.solicitacaoDados.formaPagamento && (
              <tr>
                <td className="table-row-title">Formas de Pagamento</td>
                <td>
                  {solicitacao.solicitacaoDados.formaPagamento}
                  {solicitacao.solicitacaoDados.OutraFormaPagamento
                    ? ',' + solicitacao.solicitacaoDados.OutraFormaPagamento
                    : ''}
                </td>
              </tr>
            )}
          <tr>
            <td className="table-row-title">Aprovador</td>
            <td>
              {solicitacao.materialComunicacao?.definiAprovador
                ? solicitacao.aprovador?.nome || ''
                : pedido.aprovadorMidiasDigitais?.nome || ''}
            </td>
          </tr>
        </tbody>
      </table>

      {solicitacao.solicitacaoDados && solicitacao.solicitacaoDados.layoutArte && (
        <div>
          <h3>Layout</h3>
          <div className="img-layout">
            <label>{solicitacao.solicitacaoDados.layoutArte.descricao}</label>
            <img src={solicitacao.solicitacaoDados.layoutArte.urlImagem}></img>
          </div>
        </div>
      )}

      {solicitacao.solicitacaoDados.urlsAnexoObservacoesGerais?.length > 0 && (
        <div>
          <hr />
          <h3> Anexo de Observação</h3>
          {solicitacao.solicitacaoDados.urlsAnexoObservacoesGerais?.length >
            0 &&
            solicitacao.solicitacaoDados.urlsAnexoObservacoesGerais.map(
              (urlAnexo, index) => (
                <div>
                  <div className="img-layout">
                    <div key={index} className="d-flex mb-3 align-items-start">
                      <FilePreview url={urlAnexo} />
                      <button
                        type="button"
                        className="btn btn-link p-0 pl-1"
                        onClick={() => {
                          solicitacao.solicitacaoDados.urlsAnexoObservacoesGerais.splice(
                            index,
                            1
                          );
                          updateSolicitacaoDados(solicitacaoDados);
                        }}
                      ></button>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      )}

      {solicitacao.solicitacaoEntregaDigital &&
        solicitacao.solicitacaoEntregaDigital.solicitacaoId > 0 && (
          <div className="mb-4">
            <h3>Envio</h3>
            <table className="table table-striped mb-3">
              <tbody>
                <tr>
                  <td className="table-row-title">Nome</td>
                  <td>
                    {solicitacao.solicitacaoEntregaDigital.nomeResponsavel}
                  </td>
                </tr>
                <tr>
                  <td className="table-row-title">Telefone</td>
                  <td>
                    {solicitacao.solicitacaoEntregaDigital.telefoneResponsavel}
                  </td>
                </tr>
                <tr>
                  <td className="table-row-title">Email</td>
                  <td>
                    {solicitacao.solicitacaoEntregaDigital.emailResponsavel}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

      {solicitacao.solicitacaoEnderecos &&
        solicitacao.solicitacaoEnderecos.length > 0 && (
          <div className="mb-4">
            <h3>Entrega</h3>

            {solicitacao.solicitacaoEnderecos.map((solicitacaoEndereco) => (
              <table className="table table-striped mb-3">
                <tbody>
                  <tr>
                    <td className="table-row-title">Tipo</td>
                    <td>{solicitacao.entregaTipo.descricao}</td>
                  </tr>

                  {solicitacaoEndereco.cnpJ_CPF && (
                    <tr>
                      <td className="table-row-title">CNPJ/CPF</td>
                      <td>{solicitacaoEndereco.cnpJ_CPF}</td>
                    </tr>
                  )}

                  {solicitacaoEndereco.iE_RG && (
                    <tr>
                      <td className="table-row-title">IE/RG</td>
                      <td>{solicitacaoEndereco.iE_RG}</td>
                    </tr>
                  )}

                  {solicitacaoEndereco.razaoSocial && (
                    <tr>
                      <td className="table-row-title">Código de Rastreio</td>
                      <td>{solicitacaoEndereco.razaoSocial}</td>
                    </tr>
                  )}

                  <tr>
                    <td className="table-row-title">CEP</td>
                    <td>{solicitacaoEndereco.cep}</td>
                  </tr>
                  <tr>
                    <td className="table-row-title">Endereço</td>
                    <td>{solicitacaoEndereco.endereco}</td>
                  </tr>

                  {solicitacaoEndereco.numero && (
                    <tr>
                      <td className="table-row-title">Número</td>
                      <td>{solicitacaoEndereco.numero}</td>
                    </tr>
                  )}

                  {solicitacaoEndereco.numero && (
                    <tr>
                      <td className="table-row-title">Complemento</td>
                      <td>{solicitacaoEndereco.complemento}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="table-row-title">Bairro</td>
                    <td>{solicitacaoEndereco.bairro}</td>
                  </tr>
                  <tr>
                    <td className="table-row-title">Cidade</td>
                    <td>
                      {solicitacaoEndereco.cidade.nome} -{' '}
                      {solicitacaoEndereco.cidade.uf}
                    </td>
                  </tr>
                  <tr>
                    <td className="table-row-title">Nome</td>
                    <td>{solicitacaoEndereco.nomeResponsavel}</td>
                  </tr>
                  <tr>
                    <td className="table-row-title">Telefone</td>
                    <td>{solicitacaoEndereco.telefoneResponsavel}</td>
                  </tr>
                  <tr>
                    <td className="table-row-title">Email</td>
                    <td>{solicitacaoEndereco.emailResponsavel}</td>
                  </tr>

                  {solicitacaoEndereco.codigoRastreio && (
                    <tr>
                      <td className="table-row-title">Código de Rastreio</td>
                      <td>{solicitacaoEndereco.codigoRastreio}</td>
                    </tr>
                  )}

                  {solicitacaoEndereco.dataEntrega && (
                    <tr>
                      <td className="table-row-title">Data da Entrega</td>
                      <td>{dateFormat(solicitacaoEndereco.dataEntrega)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ))}
          </div>
        )}

      {solicitacao.solicitacaoProdutos &&
        solicitacao.solicitacaoProdutos.length > 0 && (
          <div className="resumo-grid-produtos">
            <h3>Produtos</h3>
            {solicitacao.solicitacaoProdutos.map(
              (solicitacaoProduto, index) => {
                return (
                  <div className="row row-striped" key={index}>
                    <div className="col-lg-2">
                      <div className="row">
                        <div className="col-lg-12">
                          {solicitacaoProduto.urlImagem &&
                            solicitacaoProduto.urlImagem.length > 0 && (
                              <img
                                className="img-fluid"
                                src={solicitacaoProduto.urlImagem}
                              />
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-12">
                          <label>Código</label>
                          {solicitacaoProduto.produto.codigo}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <label>Produto</label>
                          {solicitacaoProduto.produto.nome}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <label>Descrição no Encarte</label>
                          {solicitacaoProduto.descricao}
                        </div>
                      </div>

                      {solicitacaoProduto.observacao && (
                        <div className="row">
                          <div
                            style={{
                              backgroundColor: '#0092c4',
                              color: '#fff',
                              borderRadius: '20px',
                            }}
                            className="col-lg-12"
                          >
                            <label>Observação</label>
                            {solicitacaoProduto.observacao}
                          </div>
                        </div>
                      )}

                      {(
                        solicitacaoProduto.solicitacaoProdutoDetalhamentos || []
                      ).length > 0 && (
                        <div className="row">
                          <div className="col-lg-12">
                            <label>Detalhes</label>
                            {(
                              (
                                solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                                []
                              ).map((x) => {
                                return solicitacaoProduto.produto?.produtoDetalhamentos.find(
                                  (detalhamento) =>
                                    detalhamento.produtoDetalhamentoId ===
                                    x.produtoDetalhamentoId
                                );
                              }) || []
                            ).map((detalhamento, index) => {
                              return (
                                <span key={index} className="d-block">
                                  {detalhamento.descricao}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-2">
                      <div className="row">
                        <div className="col-lg-12">
                          <label>Unidade</label>
                          {solicitacaoProduto.unidade}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="row">
                        {!solicitacaoProduto.oferta && (
                          <div className="col-lg-12">
                            <label>Preço</label>
                            <span className="produto-preco">
                              {solicitacaoProduto.preco
                                ? `R$ ${getStringFromPrice(
                                    solicitacaoProduto.preco
                                  )}`
                                : 'Sem precificador'}
                            </span>
                          </div>
                        )}

                        {solicitacaoProduto.oferta && (
                          <div className="col-lg-12">
                            <label>Preço OFERTA</label>
                            <span className="produto-preco">
                              de R${' '}
                              {getStringFromPrice(solicitacaoProduto.precoDe)}
                            </span>{' '}
                            <span className="produto-preco">
                              por R${' '}
                              {getStringFromPrice(solicitacaoProduto.preco)}
                            </span>
                          </div>
                        )}
                      </div>

                      {solicitacaoProduto.destaque && (
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="bg-primary text-white d-block text-center">
                              <i>Destacar</i>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}
    </div>
  );
};

export default SummarySolicitacao;
