/* eslint-disable */
import React from 'react';
import './styles.scss';

const ModalAlert = (props) => {
  window.scrollTo(0, 0);
  return (
    <>
      <div className="wrapper">
        <div className="heade-card" />
        <div className="bod-card">
          <label className="text">
            Você selecionou apenas o material
            {props.materiais.map((material, index) => (
              <strong key={index} style={{ color: 'red' }}>
                {' '}
                {material},{' '}
              </strong>
            ))}
            para impressão. Os outros materiais serão entregues apenas no
            formato digital.
            <br />
            <br />
            Deseja continuar?
            <br />
            Caso queira imprimir os outros materiais, clique em{' '}
            <strong style={{ color: 'red' }}>NÃO</strong> e selecione os outros
            materiais que deseja imprimir.
          </label>
        </div>
        <div className="bt-card">
          <button
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={props.onClose}
          >
            Não
          </button>
          <button
            className="btn btn-danger btn-navigation btn-navigation-right"
            onClick={props.onClick}
          >
            Sim
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalAlert;
