/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Context } from 'context/AuthContext';

import api from 'api';
import { GET_PEDIDO_POR_SOLICITACAO } from 'services/Pedido';
import {
  GET_SOLICITACAO,
  UPDATE_SOLICITACAO_REPROVADA_WEB,
} from 'services/Solicitacao';

import SubHeader from 'components/SubHeader';

import Entities from 'pages/PedidoMateriais/ContentPedido/entities';
import Solicitacao from 'pages/PedidoMateriais/ContentPedido/Solicitacoes/Solicitacao';
import ResumoMaterial from 'pages/PedidoMateriais/ContentPedido/Resumo/ResumoMaterial';
import Entrega from './Entrega';

import {
  tabs,
  solicitacaoTabs,
  passos,
  httpStatusCode,
} from 'pages/PedidoMateriais/ContentPedido/_constants';

import './styles.scss';

const ContentAlterarSolicitacao = () => {
  const params = useParams();
  const history = useHistory();

  const { showLoader } = useContext(Context);

  const [initialLoader, setInitialLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [entities, setEntities] = useState({});
  const [pedido, setPedido] = useState({});
  const [solicitacao, setSolicitacao] = useState({});
  const [solicitacaoAtualizada, setSolicitacaoAtualizada] = useState({});
  const [activeTab, setActiveTab] = useState(params.tab || tabs.tabEntrega);

  let urlSolicitacao = `/alterar-solicitacao/${solicitacao.solicitacaoId || 0}`;

  let material = []
    .concat(
      ...(entities.tipoMateriais || []).map(
        (tipoMaterial) => tipoMaterial.materiaisComunicacao
      )
    )
    .find((m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId);

  let errors = (solicitacao.errors || []).filter(
    (error) => error.materialComunicacaoId === 0
  );

  const updateSolicitacao = () => {
    setSolicitacao({ ...solicitacao });
  };

  const finalizar = () => {
    (async () => {
      showLoader(true);

      const response = await UPDATE_SOLICITACAO_REPROVADA_WEB(solicitacao);
      if (response?.code) {
        let code = response.code;

        // solicitacao atualizada
        if (code === httpStatusCode.ok) {
          setSolicitacaoAtualizada(response.value.solicitacao);
          changeTab(tabs.tabSucesso);
        }

        // solicitacao com erros
        else if (code === httpStatusCode.badRequest) {
          let result = response.value;
          if (result.errors && result.errors.length) {
            let errors = result.errors;

            setErrors(errors);

            redirectToError();
          }
        }
        // erro interno
        // else {
        // }
      }
      showLoader(false);
    })();
  };

  const setErrors = (errors) => {
    solicitacao.errors = errors;

    updateSolicitacao();
  };

  const redirectToError = () => {
    if (
      solicitacao.errors.findIndex(
        (error) => error.passo === passos.passoEntrega.nro
      ) !== -1
    ) {
      history.push(`${urlSolicitacao}/${tabs.tabEntrega}`);
      return;
    }

    if ((solicitacao.errors || []).length) {
      if (
        solicitacao.errors.findIndex(
          (error) => error.passo === passos.passoLayout.nro
        ) !== -1
      ) {
        history.push(`${urlSolicitacao}/${solicitacaoTabs.tabLayout}`);
        return;
      }

      if (
        solicitacao.errors.findIndex(
          (error) => error.passo === passos.passoProdutos.nro
        ) !== -1
      ) {
        history.push(`${urlSolicitacao}/${solicitacaoTabs.tabProdutos}`);
        return;
      }

      if (
        solicitacao.errors.findIndex(
          (error) => error.passo === passos.passoDados.nro
        ) !== -1
      ) {
        history.push(`${urlSolicitacao}/${solicitacaoTabs.tabDados}`);
        return;
      }
    }
  };

  const renderErrors = (errors) => {
    if (!errors || !errors.length) return null;

    return (
      <div className="alert alert-danger">
        {errors.map((error, index) => {
          return (
            <p key={index} className="m-0">
              {error.error}
            </p>
          );
        })}
      </div>
    );
  };

  const changeTab = (tab) => {
    // updateSolicitacao();
    history.push(`${urlSolicitacao}/${tab}`);
  };

  // redireciona para a tab de acordo com a mudança na url
  useEffect(() => {
    return history.listen((location) => {
      let tab = location.pathname.split('/')[3] || tabs.tabEntrega;
      setActiveTab(tab);
    });
  }, [history]);

  //  loader
  useEffect(() => {
    if (initialLoader || loader) showLoader(true);
    else showLoader(false);
  }, [initialLoader, loader]);

  // busca a solicitacao
  useEffect(() => {
    // redirecionar para home
    if (!params.solicitacaoId) {
      history.push('/');
      return;
    }

    (async () => {
      setInitialLoader(true);

      Promise.all([getSolicitacao(), getPedido()]).then(() => {
        setInitialLoader(false);
      });
    })();
  }, []);

  async function getSolicitacao() {
    const result = await GET_SOLICITACAO(params.solicitacaoId);

    if (result.value) {
      solicitacao.solicitacaoId = params.solicitacaoId;
      setSolicitacao(result.value);
    }
  }

  async function getPedido() {
    const result = await GET_PEDIDO_POR_SOLICITACAO(params.solicitacaoId);
    if (result.value) {
      setPedido(result.value);
    }
  }

  const renderTab = () => {
    if (!solicitacao.solicitacaoId) return null;

    if (activeTab === tabs.tabResumo) return renderResumo();

    if (activeTab === tabs.tabSucesso) return renderSucesso();

    return renderSolicitacao();
  };

  const renderSolicitacao = () => {
    return (
      <div className="row">
        <div className="col-lg-3 mb-3">{renderSolicitacaoSideBar()}</div>

        <div className="col-lg-9">
          <div className="solicitacao-panel">
            <div className="solicitacao-panel-header">
              SOLICITAÇÃO {solicitacao.solicitacaoId} - {material?.nome}
            </div>

            <div className="solicitacao-panel-body">
              {renderSolicitacaoTab()}

              {/* <fieldset>
                <div className="form-card">
                    <h2 className="fs-title text-center">Success !</h2> <br /><br />
                    <div className="row justify-content-center">
                        <div className="col-lg-3"> <img src="https:// img.icons8.com/color/96/000000/ok--v2.png" className="fit-image"/> </div>
                    </div> <br/><br/>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 text-center">
                            <h5>You Have Successfully Signed Up</h5>
                        </div>
                    </div>
                </div>
            </fieldset> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSolicitacaoSideBar = () => {
    if (!solicitacao.solicitacaoId) {
      return null;
    }

    return (
      <div className="solicitacao-panel">
        <div className="solicitacao-panel-header">ENTREGA</div>

        <div className="solicitacao-panel-body">
          <button
            className={`btn btn-material ${
              activeTab === tabs.tabEntrega ? 'bg-primary' : 'bg-secondary'
            }`}
            onClick={() => {
              changeTab(tabs.tabEntrega);
            }}
          >
            ENTREGA
          </button>

          <button
            className={`btn btn-material ${
              activeTab !== tabs.tabEntrega ? 'bg-primary' : 'bg-secondary'
            }`}
            onClick={() => {
              changeTab(solicitacaoTabs.tabLayout);
            }}
          >
            DADOS
          </button>
        </div>
      </div>
    );
  };

  const renderSolicitacaoTab = () => {
    if (!solicitacao.solicitacaoId) return null;

    if (activeTab === tabs.tabEntrega)
      return (
        <Entrega
          solicitacao={solicitacao}
          loja={pedido.loja}
          entities={entities}
          urlSolicitacao={urlSolicitacao}
          renderErrors={renderErrors}
          changeTab={changeTab}
        />
      );

    return (
      <Solicitacao
        solicitacao={solicitacao}
        pedidoDeCampanha={pedido.pedidoTipoId === 2}
        loja={pedido.loja}
        entities={entities}
        entregaCentroDistribuicao={pedido.loja?.enderecoFilial}
        entregaLojasParticipantes={solicitacao.entregaLojasParticipantes}
        entregaOutro={solicitacao.entregaOutro}
        proximoMaterial={() => changeTab(tabs.tabResumo)}
        renderErrors={renderErrors}
        solicitacaoIndex={0}
        urlSolicitacao={urlSolicitacao}
        locationIndexSolicitacaoTab={3}
      />
    );
  };

  const renderResumo = () => {
    return (
      <div className="wrapper-content-pedido-resumo">
        <div className="content-pedido-resumo">
          <div className="row">
            <div className="col-lg-12">
              <Accordion defaultActiveKey="0">
                <ResumoMaterial
                  solicitacao={solicitacao}
                  loja={pedido.loja}
                  entregaDigital={solicitacao.entregaDigital}
                  entregaCentroDistribuicao={
                    solicitacao.entregaCentroDistribuicao
                  }
                  aprovadorMidiasDigitais={pedido.aprovadorMidiasDigitais}
                  aprovador={solicitacao.aprovador}
                  entregaLojasParticipantes={
                    solicitacao.entregaLojasParticipantes
                  }
                  entregaOutro={solicitacao.entregaOutro}
                  entities={entities}
                  index={0}
                />
              </Accordion>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 text-left">
              <button
                className="btn btn-primary btn-navigation"
                onClick={() => changeTab(tabs.tabEntrega)}
              >
                <span>
                  <FaAngleLeft size={26} />
                </span>
                <span>VOLTAR</span>
              </button>
            </div>

            <div className="col-lg-6 d-flex flex-row-reverse">
              <button
                className="btn btn-primary btn-navigation btn-navigation-right"
                onClick={() => finalizar()}
              >
                <span>FINALIZAR</span>
                <span>
                  <FaAngleRight size={26} />
                </span>
              </button>
            </div>

            {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSucesso = () => {
    return (
      <div className="wrapper-content-pedido-resumo">
        <div className="content-pedido-resumo">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="text-center mb-5">
                A solicitação foi alterada com sucesso.
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Accordion>
                <ResumoMaterial
                  solicitacao={solicitacao}
                  loja={pedido.loja}
                  entregaDigital={solicitacao.entregaDigital}
                  entregaCentroDistribuicao={
                    solicitacao.entregaCentroDistribuicao
                  }
                  aprovadorMidiasDigitais={pedido.aprovadorMidiasDigitais}
                  aprovador={solicitacao.aprovador}
                  entregaLojasParticipantes={
                    solicitacao.entregaLojasParticipantes
                  }
                  entregaOutro={solicitacao.entregaOutro}
                  entities={entities}
                  index={0}
                />
              </Accordion>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 d-flex flex-row-reverse">
              {/* <button className="btn btn-primary btn-navigation btn-navigation-right" onClick={() => { }}>
              <span>Pagina Inicial</span>
            </button> */}

              <Link to="/">
                <button className="btn btn-primary btn-navigation btn-navigation-right">
                  <span>Pagina Inicial</span>
                </button>
              </Link>
            </div>

            {/* <pre className="text-left">{JSON.stringify(tipoMateriais, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Entities
        setEntities={setEntities}
        pedido={pedido}
        solicitacao={solicitacao}
        loader={loader}
        setLoader={setLoader}
      />

      <div className="wrapper-content-pedido mb-5">
        <SubHeader title={pedido.loja?.razaoSocial} />

        <div className="wrapper-content-alterar-solicitacao p-3">
          {renderErrors(errors)}
          {renderTab()}
        </div>
      </div>
    </>
  );
};

export default ContentAlterarSolicitacao;
