import React, { useEffect, useState, useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { Context } from 'context/AuthContext';
import { GET_LIST_AVISO } from 'services/Aviso';
import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';
import { COUNT_RASCUNHO_USUARIO_ATUAL } from 'services/Rascunho';

import rascunho from 'assets/imgs/icons/rascunho.png';
import consultar from 'assets/imgs/icons/consultar.png';
import pendencias from 'assets/imgs/icons/pendencias.png';
import relatorios from 'assets/imgs/icons/relatorios.png';
// import solicitarCampanha from 'assets/imgs/icons/solicitar_campanha.png';
import solicitarMateriais from 'assets/imgs/icons/solicitar_materiais.png';
import artesFacil from 'assets/imgs/icons/artes_facil.png';
import atendimento from 'assets/imgs/icons/atendimento_ao_usuario.png';

import './styles.scss';

const ContentHome = () => {
  const [warnings, setWarnings] = useState([]);
  const { showLoader } = useContext(Context);
  const [corPendencia, setCorPendencia] = useState([]);
  const [corRascunho, setCorRascunho] = useState([]);

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const corPendenciaResult = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setCorPendencia(corPendenciaResult.value);

      const countUsuarioAtual = await COUNT_RASCUNHO_USUARIO_ATUAL();
      setCorRascunho(countUsuarioAtual.value);

      const warningsResult = await GET_LIST_AVISO();
      setWarnings(warningsResult.value);

      showLoader(false);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="wrapper-choose-the-costumer">
        <div className="warnings">
          <Carousel>
            {warnings.map((item) => {
              return (
                <Carousel.Item key={item.avisoId}>
                  {item.urlRedirecionamento ? (
                    <a
                      href={item.urlRedirecionamento}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="d-block w-100"
                        src={item.urlImagem}
                        alt={item.texto}
                      />
                    </a>
                  ) : (
                    <img
                      className="d-block w-100"
                      src={item.urlImagem}
                      alt={item.texto}
                    />
                  )}
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        {/* <div className="actions"> */}
        <div className="actions">
          <div>
            <Link to="/pedido-materiais">
              <div>
                <div>
                  <img src={solicitarMateriais} alt="pedido-materiais" />
                </div>
                <div className="btn-divider" />
                <div>Solicitar Materiais</div>
              </div>
            </Link>
            <Link to="/atendimento">
              <div>
                <div>
                  <img src={atendimento} alt="Atendimento" />
                </div>
                <div className="btn-divider" />
                <div>Atendimento</div>
              </div>
            </Link>
            {/* <Link to="/pedido-campanha">
              <div>
                <div>
                  <img src={solicitarCampanha} alt="pedido-campanha" />
                </div>
                <div className="btn-divider" />
                <div>Solicitar Campanhas</div>
              </div>
            </Link> */}
          </div>

          <div>
            <Link to="/banco-de-artes">
              <div>
                <div>
                  <img src={artesFacil} alt="Banco de artes" />
                </div>
                <div className="btn-divider" />
                <div>Banco de Artes</div>
              </div>
            </Link>

            <Link to="/historico-pedidos">
              <div>
                <div>
                  <img src={consultar} alt="Consultar" />
                </div>
                <div className="btn-divider" />
                <div>Histórico de pedidos</div>
              </div>
            </Link>
          </div>

          <div>
            <Link to="/rascunho">
              <div style={{ backgroundColor: corRascunho > 0 ? 'red' : '' }}>
                <div>
                  <img src={rascunho} alt="Rascunhos" />
                </div>
                <div className="btn-divider" />
                <div>Rascunhos</div>
              </div>
            </Link>
            <Link to="/pendencias">
              <div style={{ backgroundColor: corPendencia > 0 ? 'red' : '' }}>
                <div>
                  <img src={pendencias} alt="pendencias" />
                </div>
                <div className="btn-divider" />
                <div>Pendências</div>
              </div>
            </Link>
          </div>
          <div>
            <Link to="/consulta">
              <div>
                <div>
                  <img src={consultar} alt="Consultar" />
                </div>
                <div className="btn-divider" />
                <div>Consultar</div>
              </div>
            </Link>

            <Link to="/relatorios">
              <div>
                <div>
                  <img src={relatorios} alt="atendimento" />
                </div>
                <div className="btn-divider" />
                <div>Relatórios</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center text-center custom-wrapper-home-helper">
        <h5 className="text-primary font-weight-bold">
          Em caso de dúvidas ou ocorrências sobre o webencartes kcc, ligar para
          (11) 98982-1821 de segunda a sexta no horário das 9:00 às 18:00 ou,
          clique em atendimento ao usuário e abra um chamado que entraremos em
          contato.
        </h5>
      </div>
    </>
  );
};

const App = () => {
  return (
    <>
      <HeaderApp />
      <ContentHome />
      <Bottom />
    </>
  );
};

export default App;
