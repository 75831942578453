/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import { Context } from 'context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import { UPDATE_SENHA } from 'services/Usuario';
import './styles.scss';

const FormPassword = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_kcc'));
  const [alterarSenha, setAlterarSenha] = useState({
    usuarioId: userAuth.userId,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const { showLoader } = useContext(Context);

  const onSubmit = async () => {
    showLoader(true);
    if (alterarSenha.novaSenha !== alterarSenha.confirmacaoSenha) {
      setErrorMessage('O campo confirmação senha deve ser igual a nova senha.');
      // resposta caso diferente
      setShowSuccess(false);
    } else {
      setShowSuccess(false);
      setErrorMessage('');

      const data = await UPDATE_SENHA(alterarSenha);

      if (data.value === false && data.errors && data.errors.length > 0) {
        setErrorMessage(data.errors[0]);
      } else if (data.value === true) {
        setShowSuccess(true);
      } else {
        setErrorMessage('Não foi possível executar esta operação!');
      }
    }
    // To do
    // todo passar para useState
    document.getElementById('senhaAtual').value = '';
    document.getElementById('novaSenha').value = '';
    document.getElementById('confirmacaoSenha').value = '';

    showLoader(false);
    return false;
  };

  function onChangeInput(event) {
    const { value, name } = event.target;

    setAlterarSenha({
      ...alterarSenha,
      [name]: value,
    });
  }

  return (
    <div>
      <div className="title">ALTERAR SENHA</div>
      <div className="info">
        <form className="formPassword">
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage('')}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          {showSuccess && (
            <Alert
              variant="success"
              onClose={() => setShowSuccess(false)}
              dismissible
            >
              Senha alterada com sucesso!
            </Alert>
          )}
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Senha Antiga
            </label>
            <div className="col-md-6">
              <input
                type="password"
                id="senhaAtual"
                name="senhaAtual"
                onChange={onChangeInput}
                className="form-control"
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Nova Senha
            </label>
            <div className="col-md-6">
              <input
                type="password"
                id="novaSenha"
                name="novaSenha"
                onChange={onChangeInput}
                className="form-control"
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <label className="col-md-4 col-form-label text-md-right">
              Confirmar Senha
            </label>
            <div className="col-md-6">
              <input
                type="password"
                id="confirmacaoSenha"
                name="confirmacaoSenha"
                onChange={onChangeInput}
                className="form-control"
              />
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className="offset-md-4 col-md-4">
              <Button
                onClick={onSubmit}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSave} /> Salvar
              </Button>
            </div>
          </FormGroup>
        </form>
      </div>
    </div>
  );
};

export default FormPassword;
