import '../styles.scss';
import React from 'react';
import Chart from 'react-apexcharts';
import SemDados from '../SemDados';

const RegionalRank = (props) => {
  const { data } = props;
  const titulo = 'SOLICITAÇÕES POR REGIONAL';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  // quantidade total de solicitações por regional
  // const itensQtdeObrigatorio = data.map((item) => {
  //   return item.totalObrigatorio;
  // });

  // const itensQtdeNaoObrigatorio = data.map((item) => {
  //   return item.totalNaoObrigatorio;
  // });

  // const maxObrigatorio = Math.max(...itensQtdeObrigatorio);
  // const maxNaoObrigatorio = Math.max(...itensQtdeNaoObrigatorio);

  // const colors = ['#F39221', '#D3D3D3'];

  // retorna uma barra com tamanho relativo a quantidade de solicitações
  // const getBar = (qtde, max, index) => {
  //   const largura = Math.round((qtde * 100) / max);
  //   return (
  //     <div className="celula-barra">
  //       <div
  //         className="barra"
  //         style={{
  //           width: `${largura * 0.7}%`,
  //           backgroundColor: colors[index % colors.length],
  //         }}
  //       />
  //       <div className="barra-valor">{qtde}</div>
  //     </div>
  //   );
  // };

  const itens = data.map((item) => {
    return item.nome;
  });

  const itensQtd = data.map((item) => {
    return item.total;
  });

  const maxValue = Math.max(...itensQtd);

  const getBarColor = (value) => {
    const porcentagem = (value / maxValue) * 100;
    return `hsl(${porcentagem}, 30%, 46%)`;
  };

  const totalTodasRegionais = data.reduce((acc, obj) => {
    return acc + obj.total;
  }, 0);

  const getPorcentagem = (value) => {
    // console.log(value, maxValue, (value / maxValue) * 100);
    return ` (${((value / totalTodasRegionais) * 100).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}%)`;
  };

  const series = [
    {
      data: itensQtd,
    },
  ];

  const options = {
    // colors: ['#001463'],
    colors: [
      // eslint-disable-next-line func-names
      function ({ value }) {
        return getBarColor(value);
      },
    ],
    plotOptions: {
      bar: {
        barHeight: '80%',
        horizontal: true,
        dataLabels: {
          position: 'top',
          orientation: 'horizontal',
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: itens,
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      max: maxValue * 1.35,
      labels: {
        align: 'left',
        minWidth: 0,
        maxWidth: 100,
        offsetX: -8,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    grid: {
      padding: {
        left: 20,
      },
      row: {
        colors: ['#dcdcdc', 'transparent'],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    chart: {
      parentHeightOffset: 0,
      width: '100%',
    },
    dataLabels: {
      hideOverflowingLabels: false,
      textAnchor: 'start',
      offsetX: 5,
      offsetY: 6,
      formatter: (value, { dataPointIndex }) => {
        return `${value.toLocaleString('pt-BR')} ${getPorcentagem(
          itensQtd[dataPointIndex]
        )}`;
      },
      style: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        colors: ['#373d3f'],
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      intersect: false,
      // eslint-disable-next-line
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        return `<div class="arrow_box">
            <span>
              <span id="data">${itens[dataPointIndex]}</span>
            </span>
            <span>
              Total de Solicitações: <span id="data">${series[seriesIndex][dataPointIndex]}</span>
            </span>
          </div>
          `;
      },
    },
  };

  return (
    <div className="grafico">
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="scrollable">
        <Chart
          options={options}
          series={series}
          height={itens.length * 17 + 100}
          type="bar"
        />
      </div>
      {/* <div className="tabela-container" style={{ overflowX: 'hidden' }}>
        <table className="tabela">
          <thead>
            <tr key="rh">
              <th className="fixed-header" style={{ width: '30%' }} key="h">
                Regional
              </th>
              <th className="fixed-header" style={{ width: '35%' }} key="ho">
                Obrigatório
              </th>
              <th className="fixed-header" style={{ width: '35%' }} key="hno">
                Não Obrigatório
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <React.Fragment key={`rf${item.nome}`}>
                  <tr
                    key={`r${item.nome}`}
                    style={{ backgroundColor: '#fafafa' }}
                  >
                    <td key={`r${item.nome}`}>{item.nome}</td>
                    <td key={`ro${item.nome}`}>
                      {getBar(itensQtdeObrigatorio[index], maxObrigatorio, 0)}
                    </td>
                    <td key={`rno${item.nome}`}>
                      {getBar(
                        itensQtdeNaoObrigatorio[index],
                        maxNaoObrigatorio,
                        1
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default RegionalRank;
