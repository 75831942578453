/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import Switch from "react-switch";
import CurrencyInput from "react-currency-input";
import Select from "react-select";

import api from "api";

import { Context } from "context/AuthContext";

import BuscaProdutos from "./BuscaProdutos";
import ProdutosEspecificos from "./ProdutosEspecificos";
import {
  GET_SOLICITACAO_PRODUTO,
  GET_LAYOUT_PRODUTOS_ESPECIFICOS,
  GET_LAYOUT_PRODUTOS_OBRIGATORIOS,
} from "services/Solicitacao";

import { passos, getCampoObrigatorioClassName } from "../../../_constants";

const Produtos = (props) => {
  const { showLoader } = useContext(Context);

  let loja = props.loja;
  let entities = props.entities;
  let clienteLayouts = props.clienteLayouts;
  let solicitacao = props.solicitacao;
  solicitacao.solicitacaoDados = solicitacao.solicitacaoDados || {};
  let solicitacaoDados = solicitacao.solicitacaoDados;
  let renderErrors = props.renderErrors;
  let errors = (solicitacao.errors || []).filter(
    (error) => error.passo === passos.passoProdutos.nro
  );
  let avancar = props.avancar;
  let hideVoltar = props.hideVoltar;
  let voltar = props.voltar;
  let material = []
    .concat(
      ...entities.tipoMateriais.map(
        (tipoMaterial) => tipoMaterial.materiaisComunicacao
      )
    )
    .find((m) => m.materialComunicacaoId === solicitacao.materialComunicacaoId);
  let layoutArte =
    (entities.clienteLayouts.layoutArtes || []).find(
      (layout) => layout.layoutArteId === solicitacaoDados.layoutArteId
    ) || {};
  let selectListUnidades = entities.unidades.map((unidade) => {
    return {
      value: unidade,
      label: unidade,
    };
  });

  const [layoutProdutosEspecificos, setLayoutProdutosEspecificos] = useState(
    solicitacao.layoutProdutosEspecificos || {}
  );
  const [layoutProdutosObrigatorios, setLayoutProdutosObrigatorios] = useState(
    solicitacao.layoutProdutosObrigatorios || {}
  );

  let minimoProdutos =
    layoutArte.minimoProdutos > 0
      ? layoutArte.minimoProdutos
      : material.minimoProdutos || 0;

  let maximoProdutos =
    layoutArte.maximoProdutos > 0
      ? layoutArte.maximoProdutos
      : material.maximoProdutos || 0;

  //pra funcionar o rascunho no mobile precisou guardar esses campos
  solicitacao.solicitacaoDados.minimoProdutos = minimoProdutos;
  solicitacao.solicitacaoDados.maximoProdutos = maximoProdutos;
  //--

  const [solicitacaoProdutos, setSolicitacaoProdutos] = useState(
    solicitacao.solicitacaoProdutos || []
  );

  solicitacao.isValidProdutos = () => {
    if ((solicitacao.solicitacaoProdutos || []).length === 0) return false;

    if (
      minimoProdutos > 0 &&
      solicitacao.solicitacaoProdutos.length < minimoProdutos
    )
      return false;

    if (
      maximoProdutos > 0 &&
      solicitacao.solicitacaoProdutos.length > maximoProdutos
    )
      return false;

    return true;
  };

  //carregar os produtos especificos do layout sempre que houver mudança de layout
  useEffect(() => {
    if (
      !layoutArte.possuiProdutosEspecificos ||
      layoutArte.layoutArteId ===
      (solicitacao.layoutProdutosEspecificos || {}).layoutArteId
    )
      return;

    (async () => {
      showLoader(true);
      const result = await GET_LAYOUT_PRODUTOS_ESPECIFICOS(layoutArte.layoutArteId);

      if (result?.value) {
        solicitacao.layoutProdutosEspecificos = result.value;
        setLayoutProdutosEspecificos(solicitacao.layoutProdutosEspecificos);
      }

      showLoader(false);
    })();
  }, [solicitacao, clienteLayouts]);

  //carregar os produtos obrigatorios do layout sempre que houver mudança de layout
  useEffect(() => {
    if (
      !layoutArte.possuiProdutosObrigatorios ||
      layoutArte.layoutArteId ===
      (solicitacao.layoutProdutosObrigatorios || {}).layoutArteId
    )
      return;

    (async () => {
      showLoader(true);

      const result = await GET_LAYOUT_PRODUTOS_OBRIGATORIOS(layoutArte.layoutArteId);

      if (result?.value) {
        solicitacao.layoutProdutosObrigatorios = result.value;
        setLayoutProdutosObrigatorios(solicitacao.layoutProdutosObrigatorios);

        adicionarProdutosObrigatorios();
      }
      showLoader(false);
    })();
  }, [solicitacao, clienteLayouts]);

  const adicionarProdutosObrigatorios = () => {
    if (
      !(
        (solicitacao.layoutProdutosObrigatorios || {}).produtosObrigatorios ||
        []
      ).length
    )
      return;

    let produtosObrigatorios =
      solicitacao.layoutProdutosObrigatorios.produtosObrigatorios;

    let naoInseridos = produtosObrigatorios.filter(
      (p) =>
        solicitacaoProdutos.findIndex((sp) => sp.produtoId === p.produtoId) ===
        -1
    );
    if (naoInseridos.length === 0) return;

    naoInseridos.forEach((produtoObrigatorio) => {
      adicionarProduto(produtoObrigatorio);
    });
  };

  const isObrigatorio = (produto) => {
    return (
      (
        (solicitacao.layoutProdutosObrigatorios || {}).produtosObrigatorios ||
        []
      ).findIndex((p) => produto.produtoId === p.produtoId) !== -1
    );
  };

  const sortedSolicitacaoProdutos = (lista) => {
    return lista.sort((produto) => (isObrigatorio(produto) ? -1 : 1));
  };

  const adicionarProduto = (produto) => {
    if (!loja || !loja.lojaId) return;

    if (
      solicitacaoProdutos.findIndex(
        (sp) => sp.produtoId === produto.produtoId
      ) !== -1
    )
      return;

    if (maximoProdutos > 0 && solicitacaoProdutos.length >= maximoProdutos)
      return;

    (async () => {
      showLoader(true);
      const result = await GET_SOLICITACAO_PRODUTO({
        lojaId: loja.lojaId,
        produtoId: produto.produtoId,
      });

      if (!result?.value) return;

      //pra funcionar no mobile
      let solicitacaoProduto = { ...result.value };
      solicitacaoProduto.produto = result.value;
      //--

      updateSolicitacaoProdutos(
        sortedSolicitacaoProdutos([...solicitacaoProdutos, solicitacaoProduto])
      );

      showLoader(false);
    })();
  };

  const removerProduto = (produto) => {
    let index = solicitacaoProdutos.findIndex(
      (sp) => sp.produtoId === produto.produtoId
    );
    if (index === -1) return;

    solicitacaoProdutos.splice(index, 1);
    updateSolicitacaoProdutos([...solicitacaoProdutos]);
  };

  const updateSolicitacaoProdutos = (lista) => {
    solicitacao.solicitacaoProdutos = lista;

    setSolicitacaoProdutos(lista);
  };

  const formatPreco = (preco) => {
    return "R$ " + preco.toFixed(2).replace(".", ",");
  };

  const getClassErrorPrecoRange = (produto) => {
    if (!produto.preco) return "";

    if (!produto.precoMinimo && !produto.precoMaximo) return "";

    let preco = parseFloat(produto.preco.toString().replace(",", "."));

    if (preco < produto.precoMinimo) return "produto-error";

    if (preco > produto.precoMaximo) return "produto-error";

    return "";
  };

  const renderFiltro = () => {
    if (!layoutArte.possuiProdutosEspecificos)
      return (
        <BuscaProdutos
          entities={entities}
          solicitacao={solicitacao}
          solicitacaoProdutos={solicitacaoProdutos}
          adicionarProduto={adicionarProduto}
        />
      );

    if (
      !layoutProdutosEspecificos ||
      !layoutProdutosEspecificos.produtosEspecificos ||
      !layoutProdutosEspecificos.produtosEspecificos.length
    )
      return (
        <BuscaProdutos
          entities={entities}
          solicitacao={solicitacao}
          solicitacaoProdutos={solicitacaoProdutos}
          adicionarProduto={adicionarProduto}
        />
      );

    return (
      <ProdutosEspecificos
        entities={entities}
        solicitacao={solicitacao}
        solicitacaoProdutos={solicitacaoProdutos}
        adicionarProduto={adicionarProduto}
        layoutProdutosEspecificos={layoutProdutosEspecificos}
      />
    );
  };

  const renderSolicitacaoProdutos = () => {
    if (!solicitacaoProdutos.length) return null;

    return (
      <div className="mt-4">
        <h5>{solicitacaoProdutos.length} produto(s) selecionado(s)</h5>

        {maximoProdutos > 0 && solicitacaoProdutos.length >= maximoProdutos && (
          <div className="alert alert-warning">
            O máximo de produtos {maximoProdutos} foi atingido, não é possível
            adicionar mais produtos.
          </div>
        )}

        <div className="grid-solicitacao-produtos">
          {solicitacaoProdutos.map((solicitacaoProduto, index) => {
            return (
              <div key={index} className="row grid-row-produto">
                <div className="col-lg-12 p-0 text-right">
                  {isObrigatorio(solicitacaoProduto) ? (
                    <i>produto obrigatório</i>
                  ) : (
                    <FaTimes
                      onClick={() => {
                        removerProduto(solicitacaoProduto);
                      }}
                    />
                  )}
                </div>
                <div className="col-lg-2 image-container">
                  <img
                    src={solicitacaoProduto.urlImagemMiniatura}
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-lg-3">
                      <label>Código</label>
                      {solicitacaoProduto.produto.codigo}
                    </div>
                    <div className="col-lg-6">
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label>Produto</label>
                          {solicitacaoProduto.produto.nome}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={`col-lg-12 ${getCampoObrigatorioClassName(
                            (
                              solicitacaoProduto.produto
                                ?.produtoDetalhamentos || []
                            ).length === 0 ||
                            (
                              solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                              []
                            ).length > 0,
                            true
                          )}`}
                        >
                          {(
                            solicitacaoProduto.produto?.produtoDetalhamentos ||
                            []
                          ).map((detalhamento, index) => {
                            return (
                              <label key={index} className="label-checkbox">
                                <input
                                  type="checkbox"
                                  checked={
                                    (
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                                      []
                                    ).findIndex(
                                      (x) =>
                                        x.produtoDetalhamentoId ===
                                        detalhamento.produtoDetalhamentoId
                                    ) !== -1
                                  }
                                  onChange={(event) => {
                                    solicitacaoProduto.solicitacaoProdutoDetalhamentos =
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos ||
                                      [];

                                    if (event.target.checked) {
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos.push(
                                        {
                                          produtoDetalhamentoId:
                                            detalhamento.produtoDetalhamentoId,
                                        }
                                      );
                                    } else {
                                      solicitacaoProduto.solicitacaoProdutoDetalhamentos =
                                        solicitacaoProduto.solicitacaoProdutoDetalhamentos.filter(
                                          (x) =>
                                            x.produtoDetalhamentoId !==
                                            detalhamento.produtoDetalhamentoId
                                        );
                                    }

                                    updateSolicitacaoProdutos([
                                      ...solicitacaoProdutos,
                                    ]);
                                  }}
                                />
                                {detalhamento.descricao}
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 pr-4 text-right">
                      <label>
                        Destacar{" "}
                        <input
                          type="checkbox"
                          checked={solicitacaoProduto.destaque}
                          onChange={(event) => {
                            solicitacaoProduto.destaque = event.target.checked;
                            updateSolicitacaoProdutos([...solicitacaoProdutos]);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label>Descrição no Encarte</label>
                      <input
                        type="text"
                        className={`form-control ${getCampoObrigatorioClassName(
                          solicitacaoProduto.descricao,
                          true
                        )}`}
                        value={solicitacaoProduto.descricao || ""}
                        onChange={(event) => {
                          solicitacaoProduto.descricao = event.target.value;
                          updateSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label>Observação</label>
                      <input
                        type="text"
                        className="form-control"
                        value={solicitacaoProduto.observacao || ""}
                        onChange={(event) => {
                          solicitacaoProduto.observacao = event.target.value;
                          updateSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {/* --------------Regra SEM oferta---------------- */}
                    <div className="col-lg-2">
                      <label>Precificador</label>
                      <Switch
                        checked={solicitacaoProduto.nome || false}
                        onChange={(checked) => {
                          solicitacaoProduto.nome = checked;
                          updateSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <div className="row">
                        {solicitacaoProduto.nome ? (
                          <><div className="col-lg-8">
                            <label>Preço</label>
                            <CurrencyInput
                              decimalSeparator=","
                              thousandSeparator=""
                              className={`form-control ${getCampoObrigatorioClassName(
                                solicitacaoProduto.preco,
                                true
                              )} ${getClassErrorPrecoRange(
                                solicitacaoProduto
                              )}`}
                              value={solicitacaoProduto.preco || ""}
                              //disabled={solicitacaoProduto.semPrecificador}
                              onChangeEvent={(event) => {
                                solicitacaoProduto.preco = event.target.value;
                                updateSolicitacaoProdutos([
                                  ...solicitacaoProdutos,
                                ]);
                              }}
                            />
                          </div>
                          </>
                        ) : (
                          <>

                            <div className="col-lg-6">
                              <label>
                                Sem precificador
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                      {/* --------------Regra com oferta---------------- */}
                      {/* <div className="col-lg-2">
                      <label>Oferta</label>
                      <Switch
                        checked={solicitacaoProduto.oferta || false}
                        onChange={(checked) => {
                          solicitacaoProduto.oferta = checked;
                          updateSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                      />
                    </div>

                    <div className="col-lg-4">
                      <div className="row">
                        {!solicitacaoProduto.oferta ? (
                          <><div className="col-lg-8">
                            <label>Preço</label>
                            <CurrencyInput
                              decimalSeparator=","
                              thousandSeparator=""
                              className={`form-control ${getCampoObrigatorioClassName(
                                solicitacaoProduto.preco,
                                true
                              )} ${getClassErrorPrecoRange(
                                solicitacaoProduto
                              )}`}
                              value={solicitacaoProduto.preco || ""}
                              disabled={solicitacaoProduto.semPrecificador}
                              onChangeEvent={(event) => {
                                solicitacaoProduto.preco = event.target.value;
                                updateSolicitacaoProdutos([
                                  ...solicitacaoProdutos,
                                ]);
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                          <label>
                            Sem precificador{" "}
                            <input
                              type="checkbox"
                              checked={solicitacaoProduto.semPrecificador}
                              onChange={(event) => {
                                solicitacaoProduto.semPrecificador = event.target.checked;
                                updateSolicitacaoProdutos([...solicitacaoProdutos]);
                              }}
                            />
                          </label>
                          </div>
                          </>
                        ) : (
                          <>
                            <div className="col-lg-6">
                              <label>De</label>
                              <CurrencyInput
                                decimalSeparator=","
                                thousandSeparator=""
                                className={`form-control ${getCampoObrigatorioClassName(
                                  solicitacaoProduto.precoDe,
                                  true
                                )}`}
                                value={solicitacaoProduto.precoDe || ""}
                                onChangeEvent={(event) => {
                                  solicitacaoProduto.precoDe =
                                    event.target.value;
                                  updateSolicitacaoProdutos([
                                    ...solicitacaoProdutos,
                                  ]);
                                }}
                              />
                            </div>

                            <div className="col-lg-6">
                              <label>Por</label>
                              <CurrencyInput
                                decimalSeparator=","
                                thousandSeparator=""
                                className={`form-control ${getCampoObrigatorioClassName(
                                  solicitacaoProduto.preco,
                                  true
                                )} ${getClassErrorPrecoRange(
                                  solicitacaoProduto
                                )}`}
                                value={solicitacaoProduto.preco || ""}
                                onChangeEvent={(event) => {
                                  solicitacaoProduto.preco = event.target.value;
                                  updateSolicitacaoProdutos([
                                    ...solicitacaoProdutos,
                                  ]);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div> */}

                      {!solicitacaoProduto.precoMinimo &&
                        !solicitacaoProduto.precoMaximo ? null : (
                        <div className="row">
                          <div className="col-lg-12">
                            min: {formatPreco(solicitacaoProduto.precoMinimo)}{" "}
                            &mdash; max:{" "}
                            {formatPreco(solicitacaoProduto.precoMaximo)}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label>Unidade</label>
                      <Select
                        options={selectListUnidades}
                        isClearable={true}
                        className={getCampoObrigatorioClassName(
                          solicitacaoProduto.unidade,
                          true
                        )}
                        onChange={(item) => {
                          solicitacaoProduto.unidade = item?.value;
                          updateSolicitacaoProdutos([...solicitacaoProdutos]);
                        }}
                        value={selectListUnidades.find(
                          (item) => item.value === solicitacaoProduto.unidade
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderErrors(errors)}

      <h4 className="text-danger text-center">
        Quantidade de Produtos Inseridos: {solicitacaoProdutos.length}
      </h4>
      <h5 className="text-danger text-center">
        Mínimo: {minimoProdutos}/Máximo: {maximoProdutos}
      </h5>
      <hr />

      {renderFiltro()}

      <hr />

      {renderSolicitacaoProdutos()}

      <div className="row mt-5">
        {!hideVoltar && (
          <div className="col btn-avancar-voltar ">
            <button
              className="btn btn-primary btn-navigation"
              onClick={() => voltar()}
            >
              <span>
                <FaAngleLeft size={26} />
              </span>
              <span>VOLTAR</span>
            </button>
          </div>
        )}

        <div className="col btn-avancar-voltar d-flex flex-row-reverse">
          <button
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={() => avancar()}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Produtos;
