/* eslint-disable */
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Row,
  Col,
  Alert,
  Tabs,
  Tab,
  Sonnet,
  FormGroup,
  Table,
} from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages,
} from 'react-icons/fa';

import './styles.scss';
import history from 'routes/history';
import { GET_EXTRATO_CUSTO_BYTES } from 'services/ExtratoSolicitacaoCusto';
import TitlePages from 'components/_base/TitlePages';
import LoaderAction from '../../../../store/modules/loader/actions';
//import InputFileCustom from "../../../atoms/InputFile";
import {
  faCaretRight,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';

const ExtratoParcial = (props) => {
  const { showLoader } = useContext(Context);
  const regionais = [];
  const filtro = {};
  const regionalId = -1; // usado para diferenciar o extrato parcial do extrato mensal.

  async function downloadExcel() {
    showLoader(true);
    let today = new Date();
    let paramFiltro = { ...filtro };
    paramFiltro.regionalId = regionalId;
    paramFiltro.ano = today.getFullYear();
    paramFiltro.mes = today.getMonth() + 1;

    let data = await GET_EXTRATO_CUSTO_BYTES(paramFiltro);

    if (data && data.value) {
      let date =
        today.getDate() +
        '_' +
        (today.getMonth() + 1) +
        '_' +
        today.getFullYear();
      let file = _base64ToByteArray(data.value);
      let blob = new Blob([file], { type: 'arraybuffer' });
      let fileName = 'KCC_Relatorio_Parcial_' + date + '.xlsx';
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    }
    showLoader(false);
  }

  function _base64ToByteArray(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  }

  return (
    <div className="extrato-parcial">
      <Button
        onClick={() => {
          downloadExcel();
        }}
      >
        <FaFileDownload size="20" />
        &nbsp; BAIXAR EXTRATO PARCIAL
      </Button>
    </div>
  );
};

export default ExtratoParcial;
