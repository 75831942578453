/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import TitlePages from 'components/_base/TitlePages';
import InputTelefone from 'components/_base/InputTelefone';
import {
  GET_APROVADOR_MODEL,
} from 'services/Solicitacao';
import { GET_USUARIO, UPDATE_TELEFONE } from 'services/Usuario';
import { tabs, passos } from '../_constants';

function ED(props) {
  const {
    userData,
    setLoader,
    updateRascunho,
    model,
    renderErrors,
    setActiveTab,
  } = props;

  const [edList, setEdList] = useState([]);
  const [ed, setEd] = useState({});
  const [aprovadorDigital, setAprovadorDigital] = useState('');
  const [disabledSelectEd, setDisabledSelectEd] = useState(false);
  const [telefoneForm, setTelefoneForm] = useState('');
  const [userTelefone, setUserTelefone] = useState('');

  const errors = (model.errors || []).filter(
    (error) => error.passo === passos.passoEd.nro
  );
  useEffect(() => {
    setEd(parseInt(model.aprovadorId));
  }, [model.aprovadorId]);

  useEffect(() => {
    (async () => {
      setLoader(true);

      // Junta os ids dos materiais em uma string
      const matIds = model.solicitacoes.map(x => x.materialComunicacao.materialComunicacaoId);
      let matIdsStr = "";
      matIds.forEach( x => { matIdsStr += (x + ", ");})

      // Obtem as informacoes de Aprovadores
      const { value: aprovadorModel } =
        await GET_APROVADOR_MODEL(matIdsStr, model.lojaId);

      const listUsuarioAprovador = aprovadorModel.selectListAprovadores;

      const definirAprovador = aprovadorModel.defineAprovador;
      const aprovadorFixo = aprovadorModel.aprovadorFixo

      if (definirAprovador && aprovadorFixo) { //----------------------------------------------------------------------------
        setDisabledSelectEd(false);
        setEdList(listUsuarioAprovador[0].options);
        const aprovador = aprovadorModel.aprovadorLoja;

        setAprovadorDigital({
          nome: aprovador.nome,
          usuarioId: aprovador.usuarioId,
        });
      } else if (definirAprovador) { //--------------------------------------------------------------------------------------
        setDisabledSelectEd(false);
        setEdList(listUsuarioAprovador[0].options);
        setAprovadorDigital('');
      } else if (aprovadorFixo) { //-----------------------------------------------------------------------------------------
        setDisabledSelectEd(aprovadorFixo);
        const aprovador = aprovadorModel.aprovadorLoja;
        setEdList([
          { label: aprovador.nome, value: aprovador.usuarioId },
        ]);
        setAprovadorDigital({
          nome: aprovador.nome,
          usuarioId: aprovador.usuarioId,
        });
        setEd(parseInt(aprovador.usuarioId));
      }

      // Distribuidor Aprovadores -------------------------------------------------------------------------------------------
      if(aprovadorModel.isDistribuidor){
        setDisabledSelectEd(false);
        setEdList(listUsuarioAprovador[0].options);
        setAprovadorDigital('');
      }

      const { value: usuarioData } = await GET_USUARIO(userData.userId);
      setUserTelefone(usuarioData.telefone);
      setTelefoneForm(usuarioData.telefone);
      setLoader(false);
    })();
  }, []);

  const alterarTelefone = async () => {
    setLoader(true);
    await UPDATE_TELEFONE(telefoneForm);
    setLoader(false);
  };

  return (
    <div className="wrapper-content-pedido-cliente">
      <TitlePages title="Escolha o ED" />
      <div className="content-pedido-cliente pt-5 mb-5">
        {renderErrors(errors)}
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h4>
              {model.solicitacoes.findIndex(
                (item) => item.materialComunicacao.definiAprovador === false
              ) !== -1 &&
                `Os materiais digitais selecionados serão encaminhados para o ED
                ${(aprovadorDigital.nome || '').toUpperCase()}
                para aprovação.`}
            </h4>
            <h4>Selecione abaixo o ED e preencha seu telefone de contato:</h4>
          </div>
          <div className="col-md-6 col-sm-12">
            <label>Escolha o ED</label>
            <Select
              name="colors"
              options={edList}
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item) => {
                setEd(parseInt(item.value));
              }}
              isDisabled={disabledSelectEd}
              value={edList.find((item) => parseInt(item.value) === ed)}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <label>Seu telefone</label>
            <InputTelefone
              value={telefoneForm || ''}
              onChange={(value) => setTelefoneForm(value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className=" text-left">
          <button
            className="btn btn-primary btn-navigation btn-navigation-left"
            onClick={() => {
              setActiveTab(tabs.tabSolicitacoes);
            }}
          >
            <span>
              <FaAngleLeft size={26} />
            </span>
            <span>VOLTAR</span>
          </button>
        </div>
        <div className=" d-flex flex-row-reverse">
          <button
            className={`btn btn-primary btn-navigation btn-navigation-right ${
              ed && telefoneForm && telefoneForm.length >= 10
                ? ''
                : 'btn-disabled'
            }`}
            onClick={() => {
              if (ed && telefoneForm && telefoneForm.length >= 10) {
                if (telefoneForm !== userTelefone) {
                  alterarTelefone();
                }
                const aprovadorFixo =
                  model.solicitacoes.findIndex(
                    (item) => item.materialComunicacao.definiAprovador === false
                  ) !== -1;
                if (aprovadorFixo) {
                  model.aprovadorMidiasDigitais = aprovadorDigital;
                }
                const nomeEd = edList.find(
                  (item) => parseInt(item.value) === ed
                );
                model.aprovadorId = ed;
                model.aprovador = {
                  usuarioId: ed,
                  nome: nomeEd.label,
                };
                model.telefone = telefoneForm;
                model.usuarioId = userData.userId;
                updateRascunho();
                setActiveTab(tabs.tabResumo);
              }
            }}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ED;
