/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { GET_BANCO_IMAGEM_CATEGORIAS } from 'services/BancoImagem';
import {
  GET_LIST_CATEGORIA_FILHAS_NORMALIZADAS,
  GET_LIST_MARCA,
} from 'services/ProdutoCategoria';

import './styles.scss';

const Filter = (props) => {
  // const [categorias, setCategorias] = useState([]);
  const [categoriasProdutos, setCategoriasProdutos] = useState([]);
  const [categoriasFilhas, setCategoriasFilhas] = useState([]);

  const [categoriasImagensSelecionadas, setCategoriasImagensSelecionadas] =
    useState([]);
  const [categoriasProdutosSelecionados, setCategoriasProdutosSelecionados] =
    useState([]);

  const [marcas, setMarcas] = useState([]);
  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);
  const [texto, setTexto] = useState('');

  function onChangeTexto(event) {
    const { value, name } = event.target;
    setTexto(value);
  }

  const onChangeCategorias = (event) => {
    let catImagens = categoriasImagensSelecionadas;
    if (event.target.checked) {
      const item = catImagens.find(
        (item) => item === parseFloat(event.target.value)
      );

      if (!item) {
        catImagens.push(parseFloat(event.target.value));
      }
    } else {
      const item = catImagens.find(
        (item) => item === parseFloat(event.target.value)
      );
      const indexItem = catImagens.findIndex(
        (item) => item === parseFloat(event.target.value)
      );

      if (item) {
        catImagens.splice(indexItem);
      }
    }

    setCategoriasImagensSelecionadas(catImagens);
  };

  const onChangeMarcas = (event) => {
    const elementValue = parseInt(event.target.value);
    const $element = event.target;
    let marcas = marcasSelecionadas;
    let items = [];

    if ($element.checked) {
      const itemMarca = marcas.find((marca) => marca === elementValue);

      if (!itemMarca) {
        marcas.push(elementValue);
      }
      setMarcasSelecionadas(marcas);

      marcas.map((marca) => {
        categoriasFilhas.map((categoria) => {
          if (categoria.superiorIds.includes(marca)) {
            if (items.length === 0) {
              items.push(categoria);
            } else {
              const objMatch = items.find(
                (obj) => JSON.stringify(obj) === JSON.stringify(categoria)
              );
              if (!objMatch) {
                items.push(categoria);
              }
            }
          }
        });
      });

      if (marcas.length === 0) {
        setCategoriasProdutos(categoriasFilhas);
      } else {
        setCategoriasProdutos(items);
      }
    } else {
      let newMarcas = [];

      for (let i = 0; i < marcas.length; i++) {
        if (marcas[i] !== elementValue) {
          newMarcas.push(marcas[i]);
        }
      }
      let newItems = [];
      const items = categoriasProdutos;

      setMarcasSelecionadas(newMarcas);

      newMarcas.map((marca) => {
        items.map((item) => {
          if (item.superiorIds.includes(marca)) {
            if (newItems.length === 0) {
              newItems.push(item);
            } else {
              const objMatch = newItems.find(
                (obj) => JSON.stringify(obj) === JSON.stringify(item)
              );
              if (!objMatch) {
                newItems.push(item);
              }
            }
          }
        });
      });

      if (newMarcas.length === 0) {
        setCategoriasProdutos(categoriasFilhas);
      } else {
        setCategoriasProdutos(newItems);
      }
    }
  };

  const onChangeCategoriasProdutos = (event) => {
    let catProdutos = categoriasProdutosSelecionados;

    if (event.target.checked) {
      const item = catProdutos.find((item) => item === event.target.value);

      if (!item) {
        catProdutos.push(event.target.value);
      }
    } else {
      const item = catProdutos.find((item) => item === event.target.value);
      const indexItem = catProdutos.findIndex(
        (item) => item === event.target.value
      );

      if (item) {
        catProdutos.splice(indexItem);
      }
    }

    setCategoriasProdutosSelecionados(catProdutos);
  };

  const filterImages = () => {
    console.log(
      texto,
      marcasSelecionadas,
      categoriasProdutosSelecionados,
      categoriasImagensSelecionadas
    );
    props.filtrar(
      texto,
      marcasSelecionadas,
      categoriasProdutosSelecionados,
      categoriasImagensSelecionadas
    );
  };

  const clearFilter = () => {
    setCategoriasImagensSelecionadas([]);
    setCategoriasProdutosSelecionados([]);
    setMarcasSelecionadas([]);
    setTexto('');

    const items = document.getElementsByTagName('INPUT');

    for (let loop = 0; loop < items.length; loop++) {
      let item = items[loop];
      if (item.type === 'checkbox' && item.checked) {
        item.checked = false;
      }
    }

    props.filtrar('', [], [], [], []);
  };

  useEffect(() => {
    // (async () => {
    //   const data = await GET_BANCO_IMAGEM_CATEGORIAS();
    //   setCategorias(data.value);
    // })();

    (async () => {
      const listaMarca = await GET_LIST_MARCA();
      setMarcas(listaMarca.value);

      const data = await GET_LIST_CATEGORIA_FILHAS_NORMALIZADAS();
      setCategoriasFilhas(data.value);
      setCategoriasProdutos(data.value);
    })();
  }, []);

  return (
    <div className="filter-body">
      <div className="titulo">FILTROS</div>
      <div className="body">
        <div className="input">
          <label>Nome ou Código</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeTexto}
            value={texto}
          />
        </div>

        {/* <hr className="default" />

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensCat">
            Categoria Imagem{" "}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: "10px" }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensCat">
            <div>
              {categorias.map((categoria) => (
                <div key={categoria.bancoImagemCategoriaId}>
                  <label style={{ fontSize: "14px" }}>
                    <input
                      onChange={onChangeCategorias}
                      type="checkbox"
                      value={categoria.bancoImagemCategoriaId}
                    />{" "}
                    {categoria.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div> */}

        <hr className="default" />

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMarca">
            Marca <FontAwesomeIcon className="float-right" icon={faPlus} />
          </label>
          <UncontrolledCollapse toggler="#itensMarca">
            <div>
              {marcas.map((marca) => {
                return (
                  <div key={marca.produtoCategoriaId}>
                    <input
                      onChange={onChangeMarcas}
                      value={marca.produtoCategoriaId}
                      type="checkbox"
                    />{' '}
                    {marca.nome}
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensProd">
            Categoria Produto{' '}
            <FontAwesomeIcon className="float-right" icon={faPlus} />
          </label>

          <UncontrolledCollapse toggler="#itensProd">
            <div>
              {categoriasProdutos.map((item) => (
                <div key={`${item.id}-${item.nome}`}>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      onChange={onChangeCategoriasProdutos}
                      type="checkbox"
                      value={item.nomeNormalizado}
                    />{' '}
                    {item.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />

        <div className="buttons">
          <FormGroup className="row">
            <div className=" col-lg-12">
              <Button
                onClick={filterImages}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSearch} /> Filtrar
              </Button>
              <Button
                onClick={clearFilter}
                className="query-button"
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faEraser} /> Limpar
              </Button>
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default Filter;
