/* eslint-disable */
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input';
import { getCampoObrigatorioClassName } from '../../../../_constants';

const Especificacoes = (props) => {
  let solicitacao = props.solicitacao;
  let obrigatorio = props.obrigatorio;
  let solicitacaoDados = solicitacao.solicitacaoDados || {};
  let updateSolicitacaoDados = props.updateSolicitacaoDados;

  const [solicitacaoEspecificacoes, setSolicitacaoEspecificacoes] = useState(
    (solicitacao.solicitacaoEspecificacoes || []).length > 0
      ? solicitacao.solicitacaoEspecificacoes
      : [{}]
  );

  const updateSolicitacaoEspecificacoes = () => {
    solicitacao.solicitacaoEspecificacoes = [...solicitacaoEspecificacoes];
    setSolicitacaoEspecificacoes(solicitacao.solicitacaoEspecificacoes);
    updateSolicitacaoDados(solicitacaoDados);
  };

  const removerEspecificacao = (index) => {
    solicitacaoEspecificacoes.splice(index, 1);
    updateSolicitacaoEspecificacoes();
  };

  const renderEspecificacao = (solicitacaoEspecificacao, index) => {
    return (
      <div
        className="col-lg-12"
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0',
          backgroundColor: '#e1e3e5',
        }}
      >
        <div
          className="row"
          style={{ justifyContent: 'flex-end', padding: '0px 25px 0 0' }}
        >
          <span style={{ cursor: 'pointer', alignSelf: 'flex-end' }}>
            {<FaTimes onClick={() => removerEspecificacao(index)} />}
          </span>
        </div>

        <div className="row  m-0 mb-2">
          <div className="col-lg-6 ">
            <label className="mb-0">Descrição </label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                solicitacaoEspecificacao.descricao,
                obrigatorio
              )}`}
              value={solicitacaoEspecificacao.descricao}
              onChange={(event) => {
                solicitacaoEspecificacao.descricao = event.target.value;
                updateSolicitacaoEspecificacoes();
              }}
            />
          </div>
          <div className="col-lg-6 ">
            <label className="mb-0">Quantidade </label>
            <input
              type="number"
              className={`form-control ${getCampoObrigatorioClassName(
                solicitacaoEspecificacao.quantidade,
                obrigatorio
              )} `}
              value={solicitacaoEspecificacao.quantidade}
              onChange={(event) => {
                solicitacaoEspecificacao.quantidade = event.target.value;
                updateSolicitacaoEspecificacoes();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="col-lg-12 mb-4">
      Especificações:
      {solicitacaoEspecificacoes.map((solicitacaoEspecificacao, index) => {
        return (
          <div key={index} className="mb-2">
            {renderEspecificacao(solicitacaoEspecificacao, index)}
          </div>
        );
      })}
      <button
        type="button"
        className="btn btn-link"
        onClick={() => {
          solicitacaoEspecificacoes.push({});
          updateSolicitacaoEspecificacoes();
        }}
      >
        incluir nova Especificação
      </button>
    </div>
  );
};

export default Especificacoes;
