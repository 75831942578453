/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';

import { FormGroup } from 'react-bootstrap';

import './styles.scss';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';

const FiltroExtrato = (props) => {
  const { showLoader } = useContext(Context);

  const [anosList, setAnosList] = useState([]);
  const [mesList, setMesList] = useState([]);
  const [dadosFiltro, setDadosFiltro] = useState({});

  const regionais = props.regionais;
  //setRegionalList(regionais);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const anos = getYears();
      setAnosList(anos);

      const meses = getMonths();
      setMesList(meses);

      props.onSelect(dadosFiltro);

      showLoader(false);
    })();
  }, [dadosFiltro]);

  useEffect(() => {
    (async () => {
      let dtHoje = new Date();
      dtHoje.setMonth(dtHoje.getMonth() - 1);

      let mes = dtHoje.getMonth() + 1;
      let ano = dtHoje.getFullYear();

      setDadosFiltro({
        ...dadosFiltro,
        mes: mes,
        ano: ano,
      });
    })();
  }, []);

  async function onChangeWithName(value, name) {
    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });
  }

  function getYears() {
    var date = new Date();
    var inicio = 2017;
    var fim = date.getFullYear();
    var options = [];

    for (var i = inicio; i <= fim; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  }

  function getMonths() {
    var date = new Date();
    var inicio = 1;
    var fim = 12;
    var options = [];

    if (dadosFiltro.ano == date.getFullYear()) {
      fim = date.getMonth() + 1;
    }

    var mesNomes = [
      '',
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];

    for (var i = inicio; i <= fim; i++) {
      options.push({ value: i, label: mesNomes[i] });
    }
    return options;
  }

  return (
    <div className="filtro-extrato">
      <FormGroup className="row">
        <label className="col-md-1 col-form-label text-md-right">Ano</label>
        <div className="col-md-2">
          <SingleSelect
            value={dadosFiltro.ano}
            name="ano"
            onChange={onChangeWithName}
            options={anosList}
          />
        </div>
        <label className="col-md-1 col-form-label text-md-right">Mês</label>
        <div className="col-md-2">
          <SingleSelect
            value={dadosFiltro.mes}
            name="mes"
            onChange={onChangeWithName}
            options={mesList}
          />
        </div>
        <label className="col-md-1 col-form-label text-md-right">
          Regional
        </label>
        <div className="col-md-4">
          <SingleSelect
            isClearable={true}
            value={dadosFiltro.regionalId}
            name="regionalId"
            onChange={onChangeWithName}
            options={regionais}
          />
        </div>
      </FormGroup>
    </div>
  );
};

export default FiltroExtrato;
