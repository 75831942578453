import React from 'react';

import './styles.scss';

const LoggedUser = () => {
  // eslint-disable-next-line no-unused-vars
  const userAuth = JSON.parse(localStorage.getItem('midias_kcc'));

  return (
    <div className="logged-user">
      <p>{userAuth.nome}</p>
    </div>
  );
};

export default LoggedUser;
